<template>
    <Header :isShow="isShow" />
    <div class="wraper">
        <div class="content_box ">
            <div class="third max_small_box">
                <div class="text" v-for="(item, index) in thirdList" :key="index" @click="handleChangeThird(index)">
                    <p :class="thirdIndex == index ? 'active' :''">{{item.name}}</p>
                </div>
            </div>
            <div class="content max_small_box" ref="header">
                <div class="list" v-for="(item, index) in detail" :key="index" data-aos="fade-up" :data-aos-delay="(index+1)*200">
                    <div class="img" >
                        <a-image class="img_s" :src="imgUrl + item.image" alt="" />
                    </div>
                    <p class="title">{{item.name}}</p>
                </div>
            </div>
            <div class="pagination max_small_box" v-if="total_page">
                <a-pagination v-model:current="page" :total="total_page" @change="handleChangePage" />
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import AboutTop from '../../components/about/AboutTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理函数
const detailEffect = (route) => {
    //详情
    const detail = ref()

     //页数
    const page = ref(1)

    //总页数
    const total_page = ref()

    const thirdList = ref()

    const thirdIndex = ref(0)

    //获取三级
    const getThirdNav = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
        }
        await post('/nav/get_third_nav', postData).then((res) => {
            // console.log('三级导航', res)
            thirdList.value = res.data
            getDetail(route.params.id, route.params.page_id)
        })
    }

    //获取详情
    const getDetail = async (id, page_id) => {
        // // console.log(thirdList.value[thirdIndex.value].id)
        detail.value = []
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
            page: page.value,
        }
        if(thirdList.value.length != 0) {
            postData.third_navigation_id = thirdList.value[thirdIndex.value].id
        }
        await post('/about/get_about_honor', postData).then((res) => {
            // console.log('荣誉', res)
            if(res.code == 1) {
                detail.value = res.data.data,
                total_page.value = res.data.total
            }
        })
    }

    //跟换三级
    const handleChangeThird = (index) => {
        page.value = 1
        thirdIndex.value = index
        getDetail(route.params.id, route.params.page_id)
    }   

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
        getDetail(route.params.id, route.params.page_id)
    }

    watchEffect(() =>{
        getThirdNav(route.params.id, route.params.page_id)
    })

    return { detail, getDetail, page, total_page, handleChangePage, thirdList, thirdIndex, handleChangeThird }
}

export default {
    name: 'HonorQualification',
    components: { Header, Footer, FloatingFrame, AboutTop },
    setup() {
        const  route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理函数
        const { detail, page, total_page, handleChangePage, thirdList, thirdIndex, handleChangeThird } = detailEffect(route)

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, page, total_page, handleChangePage, thirdList, thirdIndex, handleChangeThird }
    }
}
</script>

<style lang="scss" scoped>
.content_box{
    margin-top: 70px;
    @media screen and (max-width: 1200px){
        margin-top: 30px;
    }
}
.third{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .text{
        width: 48%;
        text-align: center;
        cursor: pointer;
        p{
            display: block;
            font-size: 16px;
            color: #666666;
            padding: 12px 0;
            border: 1px solid #dcdcdc;
            margin: 10px 0;
        }
        .active{
            color: #1f2c5c;
            border: 1px solid #1f2c5c;
        }
    }
}
.content{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .list{
        width: 17%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #F4F4F4;
        margin-bottom: 50px;
        border: 2px solid #F4F4F4;
        cursor: pointer;
        margin-right: 3.75%;
        &:nth-child(5n){
            margin-right: 0%;
        }
        &:hover{
            .img{
                .img_s{
                    transform: scale(1.1);
                }
            }
        }
        @media screen and (max-width: 1200px){
            width: 45%;
            height: 350px;
        }
        @media screen and (max-width: 900px){
            width: 45%;
            height: 200px;
            &:nth-child(5n){
                margin-right: 3%;
            }
            &:nth-child(2n){
                margin-right: 0%;
            }
        }
        .img{
            width: 100%;
            height: 160px;
            overflow: hidden;
            background: #fff;
            @media screen and (max-width: 1200px){
                height: 250px;
            }
            /* .ant-image-img{
                width: 100%;
                height: 100%;
                display: block;
                transition: .3s all;
                object-fit: scale-down;
            } */
        }
        .title{
            color: rgba(48, 90, 168, 100);
            font-size: 16px;
            margin: 25px;
            line-height: 24px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media screen and (max-width: 900px){
                padding: 15px;
                font-size: .14rem;
                -webkit-line-clamp: 1;
                margin: 0;
            }
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 80px;
    display: block;
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
/* .ant-image-img{
        width: 100%;
        height: 100%;
        display: block;
        transition: .3s all;
        object-fit: scale-down;
    } */
</style>

<style >
.ant-image{
    display: block;
    height: 100%;
}

.ant-image img{
    /* width: auto; */
    margin: 0 auto;
    text-align: center;
    /* display: block; */
}
.ant-image:hover .ant-image-img{
    transform: scale(1.1);
}
.ant-image:hover .ant-image-img img{
    width: auto;
}
.ant-image-img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    transition: .3s all;
}

.ant-image-preview-img{
    width: auto;
    margin: 0 auto;
    text-align: center;
    /* display: block; */
}
</style>